import React from 'react';
import { signOut } from 'firebase/auth';
import { auth } from '../../firebase';
import { useNavigate } from 'react-router-dom';

const SignOutButton = ({classToSet}) => {
  const navigate = useNavigate();

  const handleSignOut = async () => {
    try {
      await signOut(auth);
      navigate('/');
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  return (
    <button className={classToSet} onClick={handleSignOut}>
      <a>Wyloguj</a>
    </button>
  );
};

export default SignOutButton;

import React, { useState, useCallback, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { getFirestore, collection, addDoc, getDocs, query, where } from "firebase/firestore";
import { storage } from './../../firebase';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import './zdjecia.css'

const DropzoneComponent = ({catalogOnly=false, catalog="", callbackFunc=()=>{}, title}) => {
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [fileUrl, setFileUrl] = useState('');
  const [fileUrls, setFileUrls] = useState([]);
  const [folderName, setFolderName] = useState('');
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [labeledFiles, setLabeledFiles] = useState([]);

  const onDrop = useCallback((acceptedFiles) => {
    const allowedExtensions = ['jpg', 'jpeg', 'png', 'heic', 'mp4', 'mov', 'avi'];
    const videoExtensions = ['mp4', 'mov', 'avi']
    const filteredFiles = acceptedFiles.filter(file => {
      const fileExtension = file.name.split('.').pop().toLowerCase();
      return allowedExtensions.includes(fileExtension);
    });
    console.log('ACCEPTED FLES: ', acceptedFiles)
    const labeledFiles = filteredFiles.map(file => {
      const fileExtension = file.name.split('.').pop().toLowerCase();
      console.log('ROZSZERZENIES',fileExtension)
      if(videoExtensions.includes(fileExtension)){
        return {file: file, isVideo: true}
      } else{
        return {file: file, isVideo: false}
      }
    })

    if (filteredFiles.length === 0) {
      alert('Tylko rozszerzenia (jpg, jpeg, png, heic, mp4, mov, avi) są dozwolone');
      return;
    }
    setLabeledFiles(old => [...old, ...labeledFiles])
    setSelectedFiles(prevFiles => [...prevFiles, ...filteredFiles])
  }, []);
  const checkIsVideo = (file) => {
      let foundFile = labeledFiles.find(labeledFile => labeledFile.file === file )
      console.log(labeledFiles)
      console.log(file)
      if(foundFile){
        console.log("found file returning", foundFile.isVideo)
        return foundFile.isVideo
      }
      console.log("not found")
      return false
  }
  useEffect(() => {
    if (!catalogOnly && catalog !== ""){
      setFolderName(catalog)
    }
  },[catalog, catalogOnly])
  const uploadFiles = async () => {
    if (selectedFiles.length === 0 && !catalogOnly) {
      alert('Nie wybrano plików...');
      return;
    }
    if (!folderName) {
      alert('Nazwa folderu nie została wybrana...');
      return;
    }
  
    const db = getFirestore();
    setUploading(true);
  
    try {
      const catalogRef = collection(db, "catalogs");
      const catalogQuery = query(catalogRef, where("folderName", "==", folderName));
      const catalogSnapshot = await getDocs(catalogQuery);
  
      let catalogId;
      if (catalogSnapshot.empty) {
        const newCatalogRef = await addDoc(catalogRef, {
          folderName: folderName,
          description: "Dodaj opis dla tej realizacji...",
        });
        catalogId = newCatalogRef.id;
      } else {
        catalogId = catalogSnapshot.docs[0].id;
      }
  
      const uploadPromises = selectedFiles.map(async (file) => {
        const storageRef = ref(storage, `${folderName}/${file.name}`);
        const uploadTask = uploadBytesResumable(storageRef, file);
  
        return new Promise((resolve, reject) => {
          uploadTask.on(
            'state_changed',
            (snapshot) => {
              const fileProgress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              setProgress(fileProgress);
            },
            (error) => {
              console.error('Upload error:', error);
              reject(error);
            },
            async () => {
              const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
              await addDoc(collection(db, "photos"), {
                fileUrl: downloadURL,
                catalogId: catalogId,
                isVideo: checkIsVideo(file),
                timestamp: new Date(),
              });
              resolve(downloadURL);
            }
          );
        });
      });
  
      const fileUrls = await Promise.all(uploadPromises); // Wait for all files to upload
      setFileUrls(fileUrls); // Set URLs of all uploaded files
      setSelectedFiles([])
      callbackFunc(); // Callback after successful upload
    } catch (error) {
      console.error("Error uploading files or adding document:", error);
    } finally {
      setUploading(false);
    }
  };
  

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: true,
    accept: {
      'image/jpeg': ['.jpg', '.jpeg'],
      'image/png': ['.png'],
      'image/heic': ['.heic'],
      'video/mp4': ['.mp4'],
      'video/quicktime': ['.mov'],
      'video/x-msvideo': ['.avi'],
    },
  });

  const handleFolderChange = (e) => {
    setFolderName(e.target.value);
  };

 
  const handleSubmit = (e) => {
    e.preventDefault();
    uploadFiles();
  };

  return (
    <div className="dropzone-component">
      <h2>{title}</h2>
      <form onSubmit={handleSubmit}>
        { catalogOnly &&
          <div style={{ marginBottom: '10px' }}>
            <label htmlFor="folderName">Nazwa folderu:</label>
            <input
              type="text"
              id="folderName"
              value={folderName}
              onChange={handleFolderChange}
              required
            />
          </div>
        }
        { !catalogOnly &&
        <div className={`dragndrop ${isDragActive ? 'active' : ''}`}>
          <div className='dragndrop-dashed' {...getRootProps()}>
          {selectedFiles.length > 0 && (
            <div className="selected-files">
              <p>
                <b>Wybrane pliki</b>:
              </p>
              <ul>
                {selectedFiles.map((file, index) => (
                    <li key={index}>{file.name}</li>
                  ))}
              </ul>
            </div>
          )}

            <input {...getInputProps()} />
            <p>
              { isDragActive
                ? 'Upuść plik tutaj...'
                : 'Przeciągnij i upuść plik tutaj, lub kliknij aby wybrać plik'}
            </p>
          </div>
        </div>
        }
        <button
          type="submit"
          disabled={uploading || (selectedFiles.length === 0 && !catalogOnly) || !folderName}
        >
          {uploading ? 'Uploading...' : 'Upload'}
        </button>

      </form>

      {uploading && (
        <div className="uploading-progress">
          <p>Uploading... {Math.round(progress)}%</p>
          <div className="progress-bar-container">
            <div
              className="progress-bar"
              style={{ width: `${progress}%` }}
            ></div>
          </div>
        </div>
      )}

      {fileUrl && (
        <div className="upload-success">
          <p>Pliki zostały pomyślnie przesłane!</p>
        </div>
      )}
    </div>
  );
};

export default DropzoneComponent;

import React from 'react'
import './Footer.css'
const Footer = () => {
  return (
    <footer>
        <div className="footer-left">SOAR sp. z o.o. © Wszelkie prawa zastrzeżone, {(new Date().getFullYear())} </div>
    </footer>
  )
}

export default Footer
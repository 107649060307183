import React, { useState } from 'react';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from './../../firebase';
import { useNavigate } from 'react-router-dom';
import Navbar from '../navbar/Navbar';
import { Container, TextField, Button, Typography, Box, Alert } from '@mui/material';
import './login.css';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    setError(null);

    try {
      await signInWithEmailAndPassword(auth, email, password);
      navigate('/');
    } catch (error) {
      setError('Failed to log in. Please check your credentials.');
    }
  };

  return (
    <>
      <Navbar />
      <Container maxWidth="sm" className="login-container">
        <Box className="login-box">
          <Typography variant="h4" component="h1" gutterBottom>
            Panel administratora
          </Typography>
          <Box
            component="form"
            onSubmit={handleLogin}
            noValidate
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="login-input"
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="login-input"
            />
            {error && (
              <Alert severity="error" className="login-error">
                {error}
              </Alert>
            )}
            <Button 
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className="login-button"
            >
              Zaloguj
            </Button>
          </Box>
        </Box>
      </Container>
    </>
  );
};

export default Login;

import React from 'react';
import './zdjecia.css'
const MediaItem = ({ item, onClick }) => {
  return item.isVideo ? (
    <video
    src={item.url}
    className='media-item-video'
    playsInline
    muted
    autoPlay
    loop
    onClick={onClick}
    />
  ) : (
    <img
    srcSet={item.url}
    src={item.url}
    loading="lazy"
    onClick={onClick}
    />
  );
};

export default MediaItem;

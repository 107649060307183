import React from 'react'
import Navbar from '../navbar/Navbar'
import PhotoMenu from './PhotoMenu'
import oferta from '../../assets/zdjecia.jpeg'
import mobileimage from '../../assets/zdjecia-mobile.jpeg'
import Footer from '../Footer/Footer'
import './zdjecia.css'
const Zdjecia = () => {
  return (
    <>
    <div className="zdjecia">
      <Navbar/>
        <div className="img-container">
        <img src={oferta} alt="soar" className="img-main" />
        <div className="overlay-text">
          Zdjęcia realizacji
        </div>
        <div className="overlay-text-mobile">
            <img src={mobileimage} alt="soar" className="img-main-mobile" />
        </div>
      </div> 
      <PhotoMenu/>
    </div>
    <Footer/>
    </>
  )
}

export default Zdjecia
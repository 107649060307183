import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Oferta from './components/oferta/Oferta';
import Main from './components/main/Main';
import Zdjecia from './components/Zdjecia/Zdjecia';
import Kontakt from './components/Kontakt/Kontakt';
import PhotoGallery from './components/Zdjecia/PhotoGallery';
import { AuthProvider } from './components/Login/useAuth';
import PrivateRoute from './components/Login/PrivateRoute';
import AdminPage from './components/Login/AdminPage';
import Login from './components/Login/Login';
function App() {
  return (
      <div className="App">
      <Router>
        <AuthProvider>
          <Routes>
            <Route path="/" element={<Main />} />
            <Route path="/oferta" element={<Oferta />} />
            <Route path="/zdjecia" element={<Zdjecia />} />
            <Route path="/kontakt" element={<Kontakt />} />
            <Route path="/zdjecia/:folderId" element={<PhotoGallery />} />
            <Route path="/login" element={<Login />} />
            <Route 
            path="/admin" 
            element={
              <PrivateRoute>
                <AdminPage />
              </PrivateRoute>
            } 
          />
          </Routes>
        </AuthProvider>
      </Router>
      </div>
  );
}

export default App;

import React from 'react'
import './oferta.css'
const Container = ({text, bold}) => {
  return (
    <div class='ofert-container'>
        <p>
            <b>{bold}</b>{text}
        </p>
    </div>
  )
}

export default Container
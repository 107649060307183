import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';
import logo from '../../assets/logo.svg';
import { FaBars, FaTimes } from 'react-icons/fa';
import { useAuth } from '../Login/useAuth';
import SignOutButton from '../Login/SignOutButton';
const MOBILE_SCREEN_WIDTH = 900
const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const {adminUser} = useAuth()
  const toggleMenu = () => {
    if (window.innerWidth <= MOBILE_SCREEN_WIDTH)
    setIsOpen(!isOpen);
    if (!isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  };
  useEffect(() => {
    document.body.style.overflow = 'auto';
  }, [])

  return (
    <nav className="navbar">
      <div className="navbar-logo">
        <Link to="/">
          <img src={logo} alt="logo" />
        </Link>
      </div>
      <div className={`navbar-icon close-menu-btn ${isOpen && 'open'}`} onClick={toggleMenu}>
        {isOpen ? <FaTimes /> : <FaBars />}
      </div>
      <div className='navbar-list'>
        <ul className={`navbar-menu ${isOpen ? 'open' : ''}`}>
            <li className="navbar-item navbar-item-mobile" style={{display: `${isOpen ? "list-item" : "none"}`}}>
                <div className="navbar-logo-mobile">
                    <Link to="/">
                    <img src={logo} alt="logo" />
                    </Link>
                </div>
            </li>
            <li className="navbar-item">
            <Link to="/" onClick={toggleMenu}>Strona główna</Link>
            </li>
            <li className="navbar-item">
            <Link to="/oferta" onClick={toggleMenu}>Oferta</Link>
            </li>
            <li className="navbar-item">
            <Link to="/zdjecia" onClick={toggleMenu}>Zdjęcia z realizacji</Link>
            </li>
            <li className="navbar-item">
            <Link to="/kontakt" onClick={toggleMenu}>Kontakt</Link>
            </li>
            <li className="navbar-item navbar-item-mobile" style={{display: `${isOpen ? "list-item" : "none"}`}}>
                <button className="btn-mobile"><a href="https://dpcenergy.pl/">Sklep</a></button>
            </li>
            <li className="navbar-item navbar-item-mobile" style={{display: `${(isOpen && adminUser) ? "list-item" : "none"}`}}>
                <SignOutButton classToSet={isOpen ? "btn-mobile" : "btn"}/>
            </li>
            
        </ul>
      </div>
      <div className="buttons-container">
      {!isOpen && adminUser && <SignOutButton classToSet="btn"/>}
      <button className="btn"><a href="https://dpcenergy.pl/">Sklep</a></button>
      </div>
    </nav>
  );
}

export default Navbar;

import React from 'react'
import Navbar from '../navbar/Navbar'
import Footer from '../Footer/Footer'
import kontakt from '../../assets/kontakt.jpeg'
import mobileimage from '../../assets/kontakt-mobile.jpeg'
import './kontakt.css'

const Kontakt = () => {
  return (
    <div className='kontakt'>
      <Navbar/>
      <div className="img-container">
        <img src={kontakt} alt="soar" className="img-main" />
        <div className="overlay-text">
          Kontakt
        </div>
        <div className="overlay-text-mobile">
            <img src={mobileimage} alt="soar" className="img-main-mobile" />
        </div>
      </div> 
      <div className="kontakt-content">
      <p>
      <b>Adres:</b> ul.Główna 51A, 86-120 Pruszcz
      </p>  
      <p>
      <b>NIP: </b>739-385-18-84
      </p>  
      <p>
      <b>KRS</b> 0000403573 <b>REGON</b> 281361830
      </p>  
      <p>
      <b>Telefon kontaktowy:</b> 509 595 595
      </p>  
      </div>
      <Footer/>
    </div>
  )
}

export default Kontakt
import React from 'react';
import './Main.css';
import Navbar from '../navbar/Navbar';
import Footer from '../Footer/Footer';
import main3 from '../../assets/main-3.jpg';
import mobileimage from '../../assets/main-3-mobile.jpeg'
import turbine from '../../assets/main-turbine.jpeg';
import turbinemobile from '../../assets/main-turbine-mobile.jpg';
import { FaPhone } from "react-icons/fa6";

const Main = () => {
  return (
    <>
      <Navbar />
      <div className="img-container">
        <img src={main3} alt="soar" className="img-main" />
        <div className="overlay-text">
          SOAR,<br/>zawsze blisko<br/>Twoich marzeń.
        </div>
        <div className="overlay-text-mobile">
            <img src={mobileimage} alt="soar" className="img-main-mobile" />
        </div>

      </div> 
    <div className="content">
    <div className="content-container">
      <div>
      <h1 className='title'>Dlaczego<br/>SOAR?</h1>
      </div>
      <div>
      <p className='paragraph'>
                Jesteśmy firmą z wieloletnim doświadczeniem, która od lat z powodzeniem działa w sektorze budownictwa kubaturowego, koncentrując się na realizacji różnorodnych projektów budowlanych. Nasza specjalizacja obejmuje kompleksowe usługi w zakresie projektowania, budowy i modernizacji budynków mieszkalnych, komercyjnych, oraz przemysłowych. Dzięki szerokiej wiedzy technicznej i zaawansowanym technologiom, jesteśmy w stanie sprostać nawet najbardziej wymagającym projektom, dostosowując się do specyficznych wymagań każdego klienta.
            </p>
      </div>
    </div>
    <div className="content-container">
      <div>
      <p className='paragraph'>
              Nasze realizacje charakteryzują się najwyższą jakością wykonania, dbałością o detale oraz zgodnością z najnowszymi standardami budowlanymi. Zespół naszych wykwalifikowanych inżynierów, architektów i specjalistów budowlanych dba o to, aby każdy etap inwestycji przebiegał sprawnie i efektywnie, od fazy projektowej aż po oddanie obiektu do użytku. Jesteśmy dumni z naszego zaangażowania w tworzenie przestrzeni, które są nie tylko funkcjonalne, ale także estetyczne i przyjazne dla użytkowników.
            </p>
      </div>
      <div>
      <p className='paragraph'>
              Ponadto, aktywnie uczestniczymy w rozwijaniu zielonej energetyki w Polsce, oferując nowoczesne rozwiązania w zakresie instalacji systemów odnawialnych źródeł ciepła. Jako firma zaangażowana w zrównoważony rozwój i ochronę środowiska, jesteśmy również aktywnymi uczestnikami programu „Czyste Powietrze”. W ramach tego programu wspieramy naszych klientów w uzyskiwaniu dofinansowania oraz realizacji inwestycji, które przyczyniają się do poprawy efektywności energetycznej budynków. Nasze innowacyjne technologie oraz profesjonalne doradztwo pomagają redukować emisję zanieczyszczeń i promują zdrowsze, bardziej zrównoważone środowisko dla przyszłych pokoleń.
          </p>
      </div>
    </div>
    <div className="img-container" style={{marginTop:'2em'}}>
        <img src={turbine} alt="soar" className="img-main" />
        <div className="overlay-text-small">
              Wszystkie nasze działania, zarówno w zakresie budownictwa, jak i energii odnawialnej, są skierowane na tworzenie wartościowych, trwałych i ekologicznych rozwiązań, które odpowiadają na wyzwania współczesnego świata.
        </div>
        <div className="overlay-text-mobile">
            <img src={turbinemobile} alt="soar" className="img-main-mobile" />
        </div>

      </div> 
    
    </div>  
    <div className="content-phone">
      <h1>Chcesz dowiedzieć się więcej?</h1>
      <div className='content-phone-nr'>
        <FaPhone size='2em' className='icon' color="#fff"/>
        <a href="tel:+48509595595" className='phone-link' style={{textDecoration:'none !important'}}>
            509 595 595
        </a>
      </div>
    </div> 
    <Footer/>
    </>
  );
}

export default Main;

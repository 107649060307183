import React from 'react'
import './oferta.css'
import Navbar from '../navbar/Navbar'
import Footer from '../Footer/Footer'
import Container from './Container'
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import oferta from '../../assets/oferta.jpeg'
import mobileimage from '../../assets/oferta-mobile.jpeg'

const offers = [
  {
    title: 'Budownictwo kubaturowe ',
    text: 'to nasza główna domena. Dzięki naszemu doświadczeniu i wiedzy technicznej realizujemy projekty, które wyróżniają się solidnym wykonaniem i nowoczesnym designem. Każdy obiekt, który budujemy, jest starannie zaprojektowany i wznoszony z dbałością o najmniejsze szczegóły, co zapewnia jego trwałość i funkcjonalność na lata. Nasz zespół składa się z wykwalifikowanych specjalistów, którzy z powodzeniem łączą tradycyjne metody budowlane z najnowszymi osiągnięciami technologicznymi.',
  },
  {
    title: 'W dziedzinie nowoczesnych systemów energetycznych ',
    text: 'oferujemy kompleksowe rozwiązania, które pozwalają na znaczące obniżenie kosztów eksploatacyjnych oraz zwiększenie efektywności energetycznej budynków. Nasze instalacje, takie jak pompy ciepła i systemy fotowoltaiczne, są projektowane z myślą o maksymalnym wykorzystaniu odnawialnych źródeł energii, co przyczynia się do ochrony środowiska i tworzenia zrównoważonego otoczenia.'
  },
  {
    title: 'W ramach Programu Czyste Powietrze ',
    text: 'zapewniamy naszym klientom pełne wsparcie – od doradztwa i pomocy w uzyskaniu dofinansowania, przez realizację projektu, aż po jego późniejszą obsługę i serwis. Jesteśmy z Tobą na każdym etapie inwestycji, dbając o to, by proces przebiegał sprawnie i zgodnie z najwyższymi standardami.' },
  {
    title: 'Wybierając naszą firmę, ',
    text: 'zyskujesz partnera, który z pełnym zaangażowaniem podchodzi do każdego projektu. Nasze realizacje to nie tylko solidne budowle, ale również przemyślane rozwiązania energetyczne, które odpowiadają na wyzwania współczesności. Stawiamy na jakość, innowację i zrównoważony rozwój, dzięki czemu możesz być pewien, że Twój projekt będzie nie tylko trwały i funkcjonalny, ale także przyjazny dla środowiska.'  },
]
const responsive = {
  0: { items: 1 },
  600: {items: 1.2},
  865: { items: 1.5,
  },
  1135: {items: 2},
  1350:{ items: 2.5},
};

const Oferta = () => {

  return (
    <div>
      <Navbar/>
      <div className="img-container">
        <img src={oferta} alt="soar" className="img-main" />
        <div className="overlay-text">
          Oferta
        </div>
        <div className="overlay-text-mobile">
            <img src={mobileimage} alt="soar" className="img-main-mobile" />
        </div>
      </div> 
      <div className="offer">
        <p>
        Nasza firma to lider w branży budowlanej, specjalizujący się w kompleksowej realizacji budynków kubaturowych oraz wdrażaniu nowoczesnych technologii energetycznych. Z pasją i zaangażowaniem budujemy przyszłość, dostarczając innowacyjne rozwiązania, które łączą trwałość konstrukcji z efektywnością energetyczną.
        </p>
        <div className="test">
          <AliceCarousel 
              mouseTracking
              responsive={responsive}
              controlsStrategy="alternate"
              autoPlay={true}
              autoPlayInterval={2000}
              disableButtonsControls={true}
              items={
                  offers.map((offer) => (
                    <Container text={offer.text} bold={offer.title}/>
                  ))}
              />
        </div>

      </div>
      <div className='offer-end'>
        <h2>
        Zaufaj ekspertom i powierz nam realizację swoich planów. 
        </h2>
        <h2>
        Razem zbudujemy przyszłość na solidnych fundamentach, która będzie służyć Tobie i przyszłym pokoleniom przez wiele lat.
        </h2>
    </div>
      <Footer/>
    </div>
  )
}

export default Oferta